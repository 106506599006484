const Bomb = ({ width, height }) => {
  return (
    <svg
      height={height}
      width={width}
      version="1.1"
      id="_x32_"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g>
        <polygon
          className="st0"
          points="512,255.996 448.702,204.365 477.704,128.003 397.063,114.933 384,34.3 307.639,63.302 256,0.003 
		204.361,63.302 128.007,34.3 114.937,114.933 34.303,128.003 63.298,204.365 0,255.996 63.298,307.628 34.303,383.997 
		114.937,397.066 128.007,477.7 204.361,448.698 256,511.997 307.639,448.698 384,477.7 397.063,397.066 477.704,383.997 
		448.702,307.628 	"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default Bomb;
